const data = {
  January: [
    { date: "01-01-2021", event: "New Year", colorTheme: "World" },
    {
      date: "02-01-2021",
      event: "National Science Fiction Day",
    },
    { date: "03-01-2021", event: "" },
    { date: "04-01-2021", event: "World Braille Day", colorTheme: "World" },
    {
      date: "05-01-2021",
      event: "Deepika Padukone Birthday",
      colorTheme: "Birthday",
    },
    { date: "06-01-2021", event: "Cuddle Up Day/ National Technology Day" },
    { date: "07-01-2021", event: "" },
    { date: "08-01-2021", event: "" },
    { date: "09-01-2021", event: "" },
    {
      date: "10-01-2021",
      event: "Hritik Roshan Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "11-01-2021",
      event: "Missionary Day/ Human Trafficking Awareness Day ",
      colorTheme: "World",
    },
    {
      date: "12-01-2021",
      event: "Swami Vivekananda Jayanti/ National Youth Day",
      colorTheme: "National",
    },
    { date: "13-01-2021", event: "Lohri", colorTheme: "Sikh" },
    {
      date: "14-01-2021",
      event: "Pongal/ Makar Sankaranti/ Uttarayan",
      colorTheme: "Hindu",
    },
    {
      date: "15-01-2021",
      event: "Magh Bihu/ Kanuma Panduga/ Uzhavar Thirunal",
      colorTheme: "Hindu",
    },
    { date: "16-01-2021", event: "Thiruvalluvar Day/ National Nothing Day" },
    { date: "17-01-2021", event: "Ditch New Year's Resolution Day" },
    { date: "18-01-2021", event: "Winnie The Pooh Day" },
    { date: "19-01-2021", event: "" },
    {
      date: "20-01-2021",
      event: "Guru Govind Singh Jayanti/ Cheese Lovers Day",
      colorTheme: "Sikh",
    },
    { date: "21-01-2021", event: "National Hugging Day" },
    { date: "22-01-2021", event: "" },
    {
      date: "23-01-2021",
      event: "Netaji Subhas Chandra Bose Jayanti/ National Pie Day",
      colorTheme: "National",
    },
    { date: "24-01-2021", event: "" },
    {
      date: "25-01-2021",
      event: "State Day/ National Bubble Wrap Appreciation Day",
    },
    { date: "26-01-2021", event: "Republic Day", colorTheme: "National" },
    { date: "27-01-2021", event: "" },
    {
      date: "28-01-2021",
      event: "Data Privacy Day/ Lala Lajpat Rai Birthday",
      colorTheme: "Birthday",
    },
    { date: "29-01-2021", event: "" },
    { date: "30-01-2021", event: "Martyr's Day", colorTheme: "National" },
    { date: "31-01-2021", event: "National Hot Chocolate Day" },
  ],
  February: [
    {
      date: "01-02-2021",
      event: "National Freedom Day",
      colorTheme: "National",
    },
    { date: "02-02-2021", event: "" },
    { date: "03-02-2021", event: "" },
    { date: "04-02-2021", event: "World Cancer Day", colorTheme: "World" },
    { date: "05-02-2021", event: "World Nutella Day/ National Bubble Gum Day" },
    { date: "06-02-2021", event: "National Frozen Yogurt Day" },
    { date: "07-02-2021", event: "Rose Day" },
    { date: "08-02-2021", event: "Propose Day" },
    { date: "09-02-2021", event: "Safer Internet Day/ Chocolate Day" },
    { date: "10-02-2021", event: "Teddy Day" },
    { date: "11-02-2021", event: "Inventor's Day/ Promise Day" },
    { date: "12-02-2021", event: "Chinese New Year/ Hug Day" },
    {
      date: "13-02-2021",
      event: "World Radio Day/ Kiss Day",
      colorTheme: "World",
    },
    { date: "14-02-2021", event: "Valentine's Day" },
    { date: "15-02-2021", event: "" },
    {
      date: "16-02-2021",
      event: "Vasant Panchami/ Saraswati Puja",
      colorTheme: "Hindu",
    },
    { date: "17-02-2021", event: "Random Acts of Kindness Day " },
    { date: "18-02-2021", event: "National Drink Wine Day" },
    { date: "19-02-2021", event: "Chhatrapati Shivaji Maharaj Jayanti" },
    {
      date: "20-02-2021",
      event: "World Day of Social Justice/ Love Your Pet Day",
    },
    {
      date: "21-02-2021",
      event: "International Mother Language Day",
      colorTheme: "World",
    },
    {
      date: "22-02-2021",
      event: "National Wildlife Day/ National Margarita Day",
    },
    { date: "23-02-2021", event: "" },
    { date: "24-02-2021", event: "" },
    {
      date: "25-02-2021",
      event: "Hazarat Ali's Birthday/ Shahid Kapoor's Birthday",
      colorTheme: "Birthday",
    },
    { date: "26-02-2021", event: "" },
    { date: "27-02-2021", event: "Guru Ravidas Jayanti", colorTheme: "Hindu" },
    { date: "28-02-2021", event: "Golden Globes/ Public Sleeping Day" },
  ],
  March: [
    { date: "01-03-2021", event: "World Compliment Day", colorTheme: "World" },
    { date: "02-03-2021", event: "" },
    { date: "03-03-2021", event: "World Wildlife Day", colorTheme: "World" },
    { date: "04-03-2021", event: "National Pancake Day" },
    {
      date: "05-03-2021",
      event: "Panchayatiraj Divas/ Employee Appreciation Day",
    },
    { date: "06-03-2021", event: "" },
    { date: "07-03-2021", event: "" },
    {
      date: "08-03-2021",
      event: "Maharishi Dayanand Saraswati Jayanti/ International Women's Day",
    },
    { date: "09-03-2021", event: "" },
    {
      date: "10-03-2021",
      event: "Women & Girls HIV/ AIDS Awareness Day/ Popcorn Lover's Day",
    },
    {
      date: "11-03-2021",
      event: "Maha Shivaratri/ Popcorn Lover's Day",
      colorTheme: "Hindu",
    },
    { date: "12-03-2021", event: "" },
    { date: "13-03-2021", event: "" },
    {
      date: "14-03-2021",
      event: "Potato Chip Day/ PI Day/ Aamir Khan Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "15-03-2021",
      event: "World Consumer Rights Day/ Alia Bhatt Birthday",
      colorTheme: "World",
    },
    { date: "16-03-2021", event: "" },
    { date: "17-03-2021", event: "St. Patrick's Day", colorTheme: "Christian" },
    { date: "18-03-2021", event: "Awkward Moments Day" },
    { date: "19-03-2021", event: "World Sleep Day", colorTheme: "World" },
    {
      date: "20-03-2021",
      event:
        "March Equinox/ International Day of Happiness/ World Storytelling Day",
      colorTheme: "World",
    },
    { date: "21-03-2021", event: "World Poetry Day", colorTheme: "World" },
    {
      date: "22-03-2021",
      event:
        "World Water Day/ Bihar Day/ Gurugaddi (Throne) Day of Guru Har Rai",
      colorTheme: "World",
    },
    {
      date: "23-03-2021",
      event: "Saheed Bhagat Singh's Martyrdom Day/ National Puppy Day",
      colorTheme: "National",
    },
    { date: "24-03-2021", event: "" },
    {
      date: "25-03-2021",
      event: "Gurugaddi (Throne) Day of Guru Amar Das/ Waffle Day 2021",
      colorTheme: "Sikh",
    },
    { date: "26-03-2021", event: "" },
    {
      date: "27-03-2021",
      event: "Earth Hour Day/ World Theatre Day",
      colorTheme: "World",
    },
    {
      date: "28-03-2021",
      event: "First day of Passover/ Holika Dahan",
      colorTheme: "Hindu",
    },
    { date: "29-03-2021", event: "Holi", colorTheme: "Hindu" },
    { date: "30-03-2021", event: "" },
    {
      date: "31-03-2021",
      event:
        "World Backup Day/ The International Transgendar Day of Visibility",
      colorTheme: "World",
    },
  ],
  April: [
    {
      date: "01-04-2021",
      event: "Maundy Thursda/ April Fool's Day/ Odisha Day",
    },
    {
      date: "02-04-2021",
      event: "Good Friday/ World Autism Awareness Day",
      colorTheme: "Christian",
    },
    { date: "03-04-2021", event: "World Party Day", colorTheme: "World" },
    { date: "04-04-2021", event: "Easter Day", colorTheme: "Christian" },
    { date: "05-04-2021", event: "Babu Jagjivan Ram Jayanti" },
    {
      date: "06-04-2021",
      event: "Gurugaddi (Throne) Day of Guru Tegh Bahadur",
      colorTheme: "Sikh",
    },
    { date: "07-04-2021", event: "World Health Day", colorTheme: "World" },
    { date: "08-04-2021", event: "" },
    {
      date: "09-04-2021",
      event: "Gurgaddi Gurpurab Sri Guru Harirai ji",
      colorTheme: "Sikh",
    },
    {
      date: "10-04-2021",
      event: "National Encourage a Young Writer Day/ Siblings Day",
    },
    { date: "11-04-2021", event: "National Pet Day" },
    {
      date: "13-04-2021",
      event: "Human Space Flight Day",
      colorTheme: "World",
    },
    {
      date: "14-04-2021",
      event:
        "Chaitra Sukhladi/ Chaitra Navratri/ Ugadi/ Gudi Padwa/ Equal Pay Day/ Telugu New Year",
      colorTheme: "Hindu",
    },
    {
      date: "14-04-2021",
      event:
        "Vaisakhi/ Mesadi/ Ambedkar Jayanti/ Vishu/ Tamil New Year/ Maha Vishuba Sankranti",
      colorTheme: "Hindu",
    },
    {
      date: "15-04-2021",
      event:
        "Bahag Bihu/Vaisakhadi/ Guru Arjan dev ji/ Bengali New Year/ Himachal Day/ National High Five Day",
    },
    {
      date: "16-04-2021",
      event: "Wear Your Pajamas to Work Day/ Guru Angad Dev Jotijot",
    },
    {
      date: "17-04-2021",
      event: "Guru Hargobind Singh Jotijot/ National Cheese Ball Day",
      colorTheme: "Sikh",
    },
    {
      date: "18-04-2021",
      event: "Haiku Poetry Day/ Birthday of Guru Angad Dev",
      colorTheme: "Sikh",
    },
    { date: "19-04-2021", event: "" },
    { date: "20-04-2021", event: "" },
    { date: "21-04-2021", event: "Ram Navami", colorTheme: "Hindu" },
    { date: "22-04-2021", event: "Earth Day", colorTheme: "World" },
    { date: "23-04-2021", event: "World Book Day", colorTheme: "World" },
    {
      date: "24-04-2021",
      event: "Varun Dhawan & Sachin Tendulkar Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "25-04-2021",
      event: "Mahavir Jayanti/ Academy Awards/ World Malaria Day",
      colorTheme: "Jain",
    },
    {
      date: "26-04-2021",
      event: "Guru Harkishan Singh Jotijot, Guru Tegh Bahadur Guryai",
      colorTheme: "Sikh",
    },
    { date: "27-04-2021", event: "Hanuman Jayanti", colorTheme: "Hindu" },
    {
      date: "28-04-2021",
      event: "Denim Day/ World Day for Safety and Health at Work Observances",
      colorTheme: "World",
    },
    {
      date: "29-04-2021",
      event: "International Dance Day",
      colorTheme: "World",
    },
    {
      date: "30-04-2021",
      event: "International Jazz Day",
      colorTheme: "World",
    },
  ],
  May: [
    {
      date: "01-05-2021",
      event:
        "International Workers Day/ Maharashtra Day/ Labour Day/ Anushka Sharma Birthday/ National Fitness Day",
      colorTheme: "World",
    },
    { date: "02-05-2021", event: "World Laughter Day", colorTheme: "World" },
    {
      date: "03-05-2021",
      event: "World Press Freedom Day",
      colorTheme: "World",
    },
    {
      date: "04-05-2021",
      event: "Star Wars Day/ World Asthma Day/ Thank a Teacher Day",
      colorTheme: "World",
    },
    { date: "05-05-2021", event: "National Cartoonist Day" },
    {
      date: "06-05-2021",
      event: "Motilal Nehru/ Beverage day/ International No Diet Day",
    },
    {
      date: "07-05-2021",
      event:
        "Ravindranath Tagore Birthday/ Jamat Ul-Vida/ National Space Day/ World Password Day",
      colorTheme: "National",
    },
    {
      date: "08-05-2021",
      event: "World Red Cross and Red Crescent Day",
      colorTheme: "World",
    },
    { date: "09-05-2021", event: "Mother's Day", colorTheme: "World" },
    { date: "10-05-2021", event: "Shab-E-Qadr", colorTheme: "Muslim" },
    { date: "11-05-2021", event: "" },
    { date: "12-05-2021", event: "" },
    { date: "13-05-2021", event: "Akshaya Tritiya", colorTheme: "Hindu" },
    {
      date: "14-05-2021",
      event: "Ramzan Id/ Eid-ul-Fitar/ Maharshi Parasuram Jayanti",
      colorTheme: "Muslim",
    },
    {
      date: "15-05-2021",
      event:
        "International Day of Families/ Love a Tree Day/ Madhuri Dixit Birthday",
      colorTheme: "World",
    },
    {
      date: "16-05-2021",
      event: "Vicky Kaushal Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "17-05-2021",
      event: "World Hypertension Day",
      colorTheme: "World",
    },
    {
      date: "18-05-2021",
      event: "World Aids Vaccine Day",
      colorTheme: "World",
    },
    {
      date: "19-05-2021",
      event: "Nawazuddin Birthday",
      colorTheme: "Birthday",
    },
    { date: "20-05-2021", event: "" },
    {
      date: "21-05-2021",
      event: "Endangered Species Day/ International Tea Day",
      colorTheme: "World",
    },
    { date: "22-05-2021", event: "" },
    { date: "23-05-2021", event: "" },
    { date: "24-05-2021", event: "" },
    {
      date: "25-05-2021",
      event: "Karan Johar Birthday",
      colorTheme: "Birthday",
    },
    { date: "26-05-2021", event: "Buddha Purnima/ Vesak" },
    { date: "27-05-2021", event: "" },
    { date: "28-05-2021", event: "National Hamburger Day" },
    { date: "29-05-2021", event: "" },
    { date: "30-05-2021", event: "" },
    {
      date: "31-05-2021",
      event: "World No Tobacco Day/ Memorial Day",
      colorTheme: "World",
    },
  ],
  June: [
    { date: "01-06-2021", event: "" },
    {
      date: "02-06-2021",
      event: "Leave the Office Early Day",
      colorTheme: "World",
    },
    { date: "03-06-2021", event: "" },
    {
      date: "04-06-2021",
      event: "National Cheese Day/ Angelina Jolies Birthday",
      colorTheme: "Birthday",
    },
    { date: "05-06-2021", event: "World Environment Day", colorTheme: "World" },
    { date: "06-06-2021", event: "D-Day, WWII" },
    { date: "07-06-2021", event: "" },
    {
      date: "08-06-2021",
      event: "Best Friends Day/ Shilpa Shetty Birthday/ World Brain Tumour Day",
      colorTheme: "World",
    },
    {
      date: "09-06-2021",
      event: "National Donald Duck Day/ Sonam Kapoor Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "10-06-2021",
      event: "Iced Tea Day/ Sundar Pichai Birthday",
      colorTheme: "Birthday",
    },
    { date: "11-06-2021", event: "" },
    { date: "12-06-2021", event: "World Day Against Child Labour/ Rose Day" },
    {
      date: "13-06-2021",
      event: "Maharana Pratap Jayanti/ Christ Evan & Disha Patani Birthday",
      colorTheme: "Birthday",
    },
    { date: "14-06-2021", event: "World Blood Donor Day", colorTheme: "World" },
    { date: "15-06-2021", event: "" },
    {
      date: "16-06-2021",
      event: "Martyrdom of Guru Arjan Dev ji/ Imtiaz Ali Birthday",
      colorTheme: "Sikh",
    },
    {
      date: "17-06-2021",
      event: "Lisa Haydon Birthday",
      colorTheme: "Birthday",
    },
    { date: "18-06-2021", event: "" },
    {
      date: "19-06-2021",
      event: "Guru Hargobind Ji Birthday",
      colorTheme: "Sikh",
    },
    { date: "20-06-2021", event: "Father's Day", colorTheme: "World" },
    {
      date: "21-06-2021",
      event:
        "June Solstice/ First day of summer/ World Music Day/ International Yoga Day/ National Selfie Day/ Summer Solstice",
      colorTheme: "World",
    },
    { date: "22-06-2021", event: "" },
    { date: "23-06-2021", event: "" },
    { date: "24-06-2021", event: "" },
    { date: "25-06-2021", event: "National Take Your Dog to Work Day" },
    {
      date: "26-06-2021",
      event: "International Day Against Drug Abuse and ILLCIT Trafficking",
      colorTheme: "World",
    },
    { date: "27-06-2021", event: "" },
    { date: "28-06-2021", event: "" },
    { date: "29-06-2021", event: "" },
    {
      date: "30-06-2021",
      event: "World Social Media Day",
      colorTheme: "World",
    },
  ],
  July: [
    {
      date: "01-07-2021",
      event:
        "International Joke Day/ Kalpana Chawla Birthday/ Chartered Accountants Day",
      colorTheme: "World",
    },
    { date: "02-07-2021", event: "World UFO Day", colorTheme: "World" },
    {
      date: "03-07-2021",
      event:
        "Harbhajan Singh/ Tom Cruise Birthday/ International Day of Cooperatives ",
      colorTheme: "Birthday",
    },
    { date: "04-07-2021", event: "Independence Day USA" },
    { date: "05-07-2021", event: "" },
    {
      date: "06-07-2021",
      event: "Syama Prasad Mukherjee/ Ranveer Singh Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "07-07-2021",
      event: "World Chocolate Day/ M.S Dhoni Birthday",
      colorTheme: "World",
    },
    { date: "08-07-2021", event: "" },
    { date: "09-07-2021", event: "Tom Hanks Birthday", colorTheme: "Birthday" },
    { date: "10-07-2021", event: "" },
    {
      date: "11-07-2021",
      event: "Cheer Up The Lonely Day/ World Population Day",
    },
    { date: "12-07-2021", event: "Jagannath Rath Yatra", colorTheme: "Hindu" },
    { date: "13-07-2021", event: "" },
    { date: "14-07-2021", event: "" },
    {
      date: "15-07-2021",
      event: "National Give Something Away Day/ World Youth Skillls Day",
      colorTheme: "World",
    },
    { date: "16-07-2021", event: "" },
    { date: "17-07-2021", event: "World Emoji Day", colorTheme: "World" },
    {
      date: "18-07-2021",
      event:
        "Nelson Mandela International Day/ Priyanka Chopra/ Vin Diesel Birthday ",
      colorTheme: "World",
    },
    { date: "19-07-2021", event: "" },
    {
      date: "20-07-2021",
      event: "Naseeruddin Shah Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "21-07-2021",
      event: "Bakr Id/ Eid Al-Adha (Tentative Date)",
      colorTheme: "Muslim",
    },
    { date: "22-07-2021", event: "" },
    {
      date: "23-07-2021",
      event: "Bal Gangadhar Tilak Birthday/ Chandra Shekhar Azad Birthday",
      colorTheme: "National",
    },
    {
      date: "24-07-2021",
      event: "Guru Purnima/ Jennifer Lopez Birthday",
      colorTheme: "Hindu",
    },
    { date: "25-07-2021", event: "Parents' Day", colorTheme: "World" },
    {
      date: "26-07-2021",
      event: "Kargil Vijay Diwas/ Sandra Bullock Birthday",
      colorTheme: "National",
    },
    { date: "27-07-2021", event: "" },
    {
      date: "28-07-2021",
      event: "World Natural Conservation Day/ World Hepatitis Day",
      colorTheme: "World",
    },
    {
      date: "29-07-2021",
      event:
        "International Tiger Day/ National Lipstick Day/ Sanjay Dutt Birthday",
      colorTheme: "World",
    },
    { date: "30-07-2021", event: "Talk in an elevator Day" },
    {
      date: "31-07-2021",
      event: "Shaheed Udham Singh's Martyrdom Day",
      colorTheme: "National",
    },
  ],
  August: [
    {
      date: "01-08-2021",
      event:
        "Friendship Day/ Respect for Parents Day/ Sister's Day/ Tapsee Pannu Birthday/ International Forgiveness Day/ National Girlfriends Day",
      colorTheme: "World",
    },
    { date: "02-08-2021", event: "" },
    { date: "03-08-2021", event: "" },
    {
      date: "04-08-2021",
      event: "Barack Obama/ Kishore Kumar/ Vishal Bhardwaj Birthday",
      colorTheme: "Birthday",
    },
    { date: "05-08-2021", event: "Kajol Birthday", colorTheme: "Birthday" },
    {
      date: "06-08-2021",
      event: "International Beer Day",
      colorTheme: "World",
    },
    { date: "07-08-2021", event: "" },
    { date: "08-08-2021", event: "International Cat Day", colorTheme: "World" },
    {
      date: "09-08-2021",
      event: "Mahesh Babu Birthday/ Book Lover's Day ",
      colorTheme: "Birthday",
    },
    {
      date: "10-08-2021",
      event: "Varahagiri Venkata Giri Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "11-08-2021",
      event: "Haryali Teej/ John Abraham/ Sunil Shetty/ Jacqueline's Birthday",
      colorTheme: "Hindu",
    },
    {
      date: "12-08-2021",
      event: "International Youth Day/ World Elephant Day/ Onam (Start Day)",
      colorTheme: "World",
    },
    {
      date: "13-08-2021",
      event:
        "Nag Panchami/ International Left Hander's Day/ Friday The 13th/ Blame Someone Else Day",
      colorTheme: "Hindu",
    },
    {
      date: "14-08-2021",
      event: "Sunidhi Chauhan Birthday/ Chinese Valentine's Day ",
      colorTheme: "Birthday",
    },
    { date: "15-08-2021", event: "Independence Day", colorTheme: "National" },
    {
      date: "16-08-2021",
      event: "Parsi New Year/ Saif Ali Khan Birthday",
      colorTheme: "Birthday",
    },
    { date: "17-08-2021", event: "National Thriftshop Day" },
    { date: "18-08-2021", event: "" },
    {
      date: "19-08-2021",
      event:
        "Muharram/ Ashura (Tentative Date)/ Eid Al Adha/ World Photo Day/ World Humanitarian Day/ Shankar Dayal Sharma Birthday",
      colorTheme: "Muslim",
    },
    {
      date: "20-08-2021",
      event: "Eid Al Adha/ National Radio Day",
      colorTheme: "Muslim",
    },
    {
      date: "21-08-2021",
      event: "Thiruvonam/ Eid Al Adha/ Onam/ Senior Citizen's Day",
    },
    { date: "22-08-2021", event: "Raksha Bandhan", colorTheme: "Hindu" },
    { date: "23-08-2021", event: "Onam (End Day)", colorTheme: "Hindu" },
    { date: "24-08-2021", event: "" },
    { date: "25-08-2021", event: "Kiss and Make-Up Day" },
    {
      date: "26-08-2021",
      event: "Mother Teresa/ Women's Equality Day/ National Dog Day ",
      colorTheme: "World",
    },
    { date: "27-08-2021", event: "" },
    { date: "28-08-2021", event: "" },
    { date: "29-08-2021", event: "" },
    {
      date: "30-08-2021",
      event: "Janmashtami/ Frankenstein Day",
      colorTheme: "Hindu",
    },
    { date: "31-08-2021", event: "" },
  ],
  September: [
    { date: "01-09-2021", event: "" },
    { date: "02-09-2021", event: "VJ Day, WWII" },
    { date: "03-09-2021", event: "" },
    {
      date: "04-09-2021",
      event:
        "National Wildlife Day/ Rishi Kapoor/ National Wildlife/ Beyonce Birthday/ World Beard Day",
      colorTheme: "National",
    },
    {
      date: "05-09-2021",
      event:
        "International Day of Charity/ Teachers' Day/ Sarvepalli Radhakrishnan Birthday/ Pankaj Tripathy Birthday/ National Cheese Pizza Day",
      colorTheme: "World",
    },
    {
      date: "06-09-2021",
      event: "National Read a Book Day/ Fight Procrastination Day",
    },
    { date: "07-09-2021", event: "" },
    {
      date: "08-09-2021",
      event: "International Literacy Day",
      colorTheme: "World",
    },
    { date: "09-09-2021", event: "" },
    {
      date: "10-09-2021",
      event: "Ganesh Chaturthi/ Vinayaka Chaturthi",
      colorTheme: "Hindu",
    },
    { date: "11-09-2021", event: "911 Remembrance/ Make Your Bed Day" },
    {
      date: "12-09-2021",
      event: "Grandparent's Day/ National Vide Games Day",
      colorTheme: "World",
    },
    { date: "13-09-2021", event: "Positive Thinking Day", colorTheme: "World" },
    { date: "14-09-2021", event: "Hindi Diwas", colorTheme: "National" },
    { date: "15-09-2021", event: "Engineers Day", colorTheme: "World" },
    {
      date: "16-09-2021",
      event: "Vishwakarma Puja/ Working Parents Day",
      colorTheme: "Hindu",
    },
    {
      date: "17-09-2021",
      event: "Narendra Modi Birthday",
      colorTheme: "Birthday",
    },
    { date: "18-09-2021", event: "National Dance Day" },
    {
      date: "19-09-2021",
      event: "Anant Chaturdashi/ Lucky Ali Birthday",
      colorTheme: "Birthday",
    },
    { date: "20-09-2021", event: "National Punch Day" },
    {
      date: "21-09-2021",
      event:
        "International Day of Peace/ World Gratitude Day/ National Chai Day/ Kareena Kapoor Khan Birthday",
      colorTheme: "World",
    },
    {
      date: "22-09-2021",
      event: "World Rhino Day/ Autumn Equinox ",
      colorTheme: "World",
    },
    { date: "23-09-2021", event: "Haryana Hero's Martyrdom Day" },
    { date: "24-09-2021", event: "" },
    { date: "25-09-2021", event: "National Comic Book Day" },
    { date: "26-09-2021", event: "" },
    {
      date: "27-09-2021",
      event: "Bhagat Singh Birthday/ World Tourism Day",
      colorTheme: "Birthday",
      colorTheme: "National",
    },
    {
      date: "28-09-2021",
      event: "Ranbir Kapoor Birthday",
      colorTheme: "Birthday",
    },
    { date: "29-09-2021", event: "" },
    {
      date: "30-09-2021",
      event: "International Podcast Day",
      colorTheme: "World",
    },
  ],
  October: [
    {
      date: "01-10-2021",
      event:
        "International Coffee Day/ International Day of Older Persons/ World Vegetarian Day/ Ram Nath Kovind Birthday",
      colorTheme: "World",
    },
    {
      date: "02-10-2021",
      event: "Mahatma Gandhi Jayanti/ Lal Bahadur Shastri Birthday",
      colorTheme: "National",
    },
    { date: "03-10-2021", event: "National Boyfriend's Day" },
    {
      date: "04-10-2021",
      event:
        "World Animal Day/ World Animal Welfare Day/ World Habitat Day/ World Smile Day",
      colorTheme: "World",
    },
    { date: "05-10-2021", event: "" },
    { date: "06-10-2021", event: "World Chicken Day", colorTheme: "World" },
    { date: "07-10-2021", event: "" },
    {
      date: "08-10-2021",
      event: "Indian Air Force Day",
      colorTheme: "National",
    },
    { date: "09-10-2021", event: "World Post Office Day", colorTheme: "World" },
    {
      date: "10-10-2021",
      event: "World Mental Health Day/ Rekha Birthday/ Rakul Preet Birthday",
      colorTheme: "World",
    },
    {
      date: "11-10-2021",
      event: "International Day Of The Girl/ Amitabh Bachchan Birthady",
      colorTheme: "World",
    },
    { date: "12-10-2021", event: "Maha Saptami", colorTheme: "Hindu" },
    {
      date: "13-10-2021",
      event: "Maha Ashtami/ Durga Puja",
      colorTheme: "Hindu",
    },
    {
      date: "14-10-2021",
      event: "Maha Navami/ National Dessert Day/ World Sight Day",
    },
    {
      date: "15-10-2021",
      event:
        "Dussehra/ Vijayadasami/ Global Handwashing Day/ World Students Day/ Dr. A. P. J. Abdul Kalam Birthday",
      colorTheme: "Hindu",
    },
    {
      date: "16-10-2021",
      event: "World Food Day / Boss's Day/ Sweetest Day/ Hema Malini Birthday",
      colorTheme: "World",
    },
    {
      date: "17-10-2021",
      event: "Eradication of Poverty Day/ National Pasta Day",
      colorTheme: "World",
    },
    {
      date: "18-10-2021",
      event: "Kati Bihu/ Id-e Milad (Tentative Date)/ No Bread Day",
    },
    {
      date: "19-10-2021",
      event: "Milad un-Nabi/ Id-e-Milad (Tentative Date)",
      colorTheme: "Muslim",
    },
    {
      date: "20-10-2021",
      event: "Maharishi Valmiki Jayanti/ Birth of the Guru Granth.",
      colorTheme: "Hindu",
    },
    { date: "21-10-2021", event: "Reptile Awareness Day", colorTheme: "World" },
    {
      date: "22-10-2021",
      event: "Kader Khan Birthday/ Parineeti Chopra Birthday",
      colorTheme: "Birthday",
    },
    { date: "23-10-2021", event: "Prabhas Birthday", colorTheme: "Birthday" },
    {
      date: "24-10-2021",
      event: "Karva Chauth/ United Nations Day/ Karaka Chaturthi ",
      colorTheme: "Hindu",
    },
    { date: "25-10-2021", event: "Greasy Foods Day", colorTheme: "World" },
    {
      date: "26-10-2021",
      event: "National Pumpkin Day/ Raveena Tandon's Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "27-10-2021",
      event: "Anuradha Paudwal's Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "28-10-2021",
      event: "",
    },
    {
      date: "29-10-2021",
      event: "Vijendra Singh Birthday/ Birth Bhagat Namdev Ji",
      colorTheme: "Birthday",
    },
    {
      date: "30-10-2021",
      event:
        "Checklist Day / World Thrift Day/ Abhijeet Bhattacharya Birthday/ Homi J Bhabha/ World Savings Day",
      colorTheme: "World",
    },
    {
      date: "31-10-2021",
      colorTheme: "Christian",
      event: "Halloween/ Sardar Vallabhbhai Patel Jayanti",
    },
  ],
  November: [
    {
      date: "01-11-2021",
      event: "World Vegan Day/ Aishwarya Rai Bachan Birthday/ Haryana Day",
      colorTheme: "World",
    },
    {
      date: "02-11-2021",
      event: "Dhanteras/ Shahrukh Khan Birthday",
      colorTheme: "Hindu",
    },
    {
      date: "03-11-2021",
      event: "Prithviraj Kapoor Birthay/ International Stress Awareness Day",
      colorTheme: "World",
    },
    {
      date: "04-11-2021",
      event: "Naraka Chaturdasi/ Diwali/ Aurangzeb's Birthday",
      colorTheme: "Hindu",
    },
    {
      date: "05-11-2021",
      event: "Govardhan Puja/ Virat Kohli Birthday",
      colorTheme: "Hindu",
    },
    { date: "06-11-2021", event: "Bhai Duj", colorTheme: "Hindu" },
    {
      date: "07-11-2021",
      event:
        "National Cancer Awareness Day/ Kamal Haasan's Birthday/ C. V. Raman's Birthday",
      colorTheme: "World",
    },
    {
      date: "08-11-2021",
      event: "L. K. Advani's Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "09-11-2021",
      event: "",
    },
    {
      date: "10-11-2021",
      event:
        "Chhat Puja (Pratihar Sashthi/ Surya Sashthi)/ World Science Day for Peace and Development",
      colorTheme: "Hindu",
    },
    {
      date: "11-11-2021",
      event: "Robin Uthappa's Birthday",
      colorTheme: "Birthday",
    },
    { date: "12-11-2021", event: "World Pneumonia Day", colorTheme: "World" },
    { date: "13-11-2021", event: "World Kindness Day", colorTheme: "World" },
    {
      date: "14-11-2021",
      event: "Children's Day/ Jawaharlal Nehru's Birthday/ World Diabetes Day",
    },
    { date: "15-11-2021", event: "Clean Out Your Refrigerator Day" },
    {
      date: "16-11-2021",
      event: "International Day for Tolerance",
      colorTheme: "World",
    },
    {
      date: "17-11-2021",
      event: "International Students Day/ Yusuf Pathan/ NAtional Epilepsy Day",
      colorTheme: "World",
    },
    {
      date: "18-11-2021",
      event: "Kamal Nath Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "19-11-2021",
      event:
        "Guru Nanak Jayanti/ International Men's Day/ Indira Gandhi/ Rani of Jhansi/ Dara Singh/ World Toilet Day",
      colorTheme: "Sikh",
    },
    {
      date: "20-11-2021",
      event:
        "International Children's Day/ Transgender Day of Rememberance/ Tipu Sultan/ World Children's Day",
      colorTheme: "World",
    },
    {
      date: "21-11-2021",
      event: "World Hello Day, World Television Day",
      colorTheme: "World",
    },
    {
      date: "22-11-2021",
      event: "Mulayam Singh Yadav Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "23-11-2021",
      event: "",
    },
    {
      date: "24-11-2021",
      event: "Guru Tegh Bahadur's Martyrdom Day/ Amit Mishra's Birthday",
      colorTheme: "Sikh",
    },
    {
      date: "25-11-2021",
      event:
        "International Day for The Elimination of Violence Against Women/ Jhulan Goswami Birthday",
      colorTheme: "World",
    },
    {
      date: "26-11-2021",
      event: "Constitution Day of India",
      colorTheme: "National",
    },
    {
      date: "27-11-2021",
      event: "Suresh Raina's Birthday/ Harivansh Rai Bachchan's Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "28-11-2021",
      event: "Birth Sahibzada Baba Zorawar Singh Ji/ Red Planet Day",
      colorTheme: "Sikh",
    },
    { date: "29-11-2021", event: "First Day of Hanukkah/ Cyber Monday" },
    { date: "30-11-2021", event: "" },
  ],
  December: [
    {
      date: "01-12-2021",
      event: "World AIDS Day/ Mohammad Kaif's Birthday/ Social Samosa Day",
      colorTheme: "World",
    },
    {
      date: "02-12-2021",
      event: "World Computer Literacy Day/ National Pollution Control Day",
      colorTheme: "World",
    },
    {
      date: "03-12-2021",
      event:
        "International Day of Disabled Persons/Dr. Rajendra Prasad, Mithali Raj Birthday",
      colorTheme: "World",
    },
    {
      date: "04-12-2021",
      event: "Indian Navy Day/ Ajit Agarkar/ Javed Jaffrey Birthday",
      colorTheme: "National",
    },
    {
      date: "05-12-2021",
      event: "World Soil Day/ Shikhar Dhawan's Birthday",
      colorTheme: "World",
    },
    {
      date: "06-12-2021",
      event:
        "Microwave Oven Day/ Jasprit Bumrah/ Ravindra Jadeja Birthday/ Last day of Hanukkah",
      colorTheme: "Birthday",
    },
    {
      date: "07-12-2021",
      event: "Indian Armed Forces Flag Day/ International Civil Aviation Day",
      colorTheme: "National",
    },
    {
      date: "08-12-2021",
      event: "Dharmendra/ Sharmila Tagore Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "09-12-2021",
      event: "Sonia Gandhi/ Shatrughan Sinha Birthday",
      colorTheme: "Birthday",
    },
    { date: "10-12-2021", event: "Human Right's Day", colorTheme: "World" },
    {
      date: "11-12-2021",
      event:
        "International Mountain Day/ Dilip Kumar Birthday/ Pranab Mukherjee Birthday/ International Unicef Day",
      colorTheme: "World",
    },
    {
      date: "12-12-2021",
      event:
        "Birth Sahibzada Baba Fateh Singh Ji/ Rajinikanth/ Sharad Pawar/ Yuvraj Singh's Birthday",
      colorTheme: "Sikh",
    },
    { date: "13-12-2021", event: "" },
    {
      date: "14-12-2021",
      event:
        "National Energy Conservation Day/ Sanjay Gandhi/ Shiromani Akali Dal Establishment Day",
    },
    { date: "15-12-2021", event: "" },
    { date: "16-12-2021", event: "" },
    {
      date: "17-12-2021",
      event: "John Abraham/ Ritesh Desmukh Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "18-12-2021",
      event: "Minorities Rights Day/ International Migrants Day",
      colorTheme: "World",
    },
    {
      date: "19-12-2021",
      event: "Pratibha Patil's Birthday/ Goa's Liberation Day",
      colorTheme: "Birthday",
    },
    {
      date: "20-12-2021",
      event: "International Human Solidarity Day/ Taimur Birthday",
      colorTheme: "World",
    },
    {
      date: "21-12-2021",
      event:
        "December Solstice/ Winter Solstice/ Crossword Puzzle Day/ First Day of Winter/ Govinda/ Krishnamachari Srikkanth Birthday",
      colorTheme: "World",
    },
    { date: "22-12-2021", event: "" },
    {
      date: "23-12-2021",
      event: "Kisan Divas/ Chaudhary Charan Singh's Birthday",
      colorTheme: "National",
    },
    {
      date: "24-12-2021",
      event:
        "Atal Bihari Vajpayee Birthday/ National Consumer Rights Day/ Anil Kapoor Birthday/ Christmas Eve/",
      colorTheme: "National",
    },

    {
      date: "25-12-2021",
      event: "Christmas/ Madan Mohan Malaviya Birthday/ Good Governance Day",
      colorTheme: "Christian",
    },
    { date: "26-12-2021", event: "Boxing Day", colorTheme: "World" },
    {
      date: "27-12-2021",
      event: "No Interruptions Day/ Salman Khan Birthday",
      colorTheme: "World",
    },
    {
      date: "28-12-2021",
      event: "Arun Jaitley's Birthday",
      colorTheme: "Birthday",
    },
    {
      date: "29-12-2021",
      event: "Rajesh Khanna's Birthday",
      colorTheme: "Birthday",
    },
    { date: "30-12-2021", event: "" },
    { date: "31-12-2021", event: "New Year's Eve", colorTheme: "World" },
  ],
};

export default data;
