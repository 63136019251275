import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./style.scss";
import moment from "moment";
import arrow from "../../images/down-arrow.png";
import data from "./data";
import axios from "axios";
import ohmSymbol from "../../images/ohm.png";
import moonSymbol from "../../images/crecentmoon.png";
import kandhaSymbol from "../../images/khandha.png";
import crossSymbol from "../../images/cross.png";
import worldSymbol from "../../images/world.png";
import ahimsaSymbol from "../../images/ahimsa.png";
import flag from "../../images/flag.png";
import baloonIcon from "../../images/balloon.png";

function HashtagPopup({ hashtags, onClickClose, loading }) {
  return (
    <div className="hashtagpopup">
      <button onClick={onClickClose}>Close</button>

      {loading ? (
        <div className="hashtagpopup__loading">Fetching Data.......</div>
      ) : (
        hashtags.map((item) => (
          <>
            <div className="hashtagpopup__hashtag">{item.hashtag}</div>
            <div className="hashtagpopup__tags">{item.tags}</div>
          </>
        ))
      )}
    </div>
  );
}

export default function CalendarPage() {
  const [showHashtagPopup, setShowHashtagPopup] = useState(false);
  const [hashtags, setHashtags] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const gridboxes = [];

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const monthNow = new Date().getMonth();
  //   const monthNow = 0;
  const monthNextFromNow = getNextMonth(monthNow);
  const monthPrevFromNow = getPrevMonth(monthNow);

  function getNextMonth(month) {
    let m = month + 1;

    if (m > 11) {
      m = m - 12;
    }
    return m;
  }

  function getPrevMonth(month) {
    let m = month - 1;

    if (m < 0) {
      m = m + 12;
    }
    return m;
  }

  function toggleHashtagPopup() {
    setShowHashtagPopup(!showHashtagPopup);
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentMonth, setCurrentMonth] = useState(monthNow);
  const [prevMonth, setPrevMonth] = useState(monthPrevFromNow);
  const [nextMonth, setNextMonth] = useState(monthNextFromNow);

  weekDays.forEach((item) => {
    gridboxes.push(<div className="calendar__grid__item--header">{item}</div>);
  });

  async function fetchHashtags(events) {
    const keywordsWithWhiteSpaces = events.split("/");

    setIsFetching(true);

    const keywords = [];

    for (let i = 0; i < keywordsWithWhiteSpaces.length; i++) {
      let stripped = keywordsWithWhiteSpaces[i].replace(/\s+/g, "");
      keywords.push(stripped);
    }

    console.log("here", keywords);
    // return;
    try {
      const response = await axios.post(
        "https://fetcher-hashtags.vercel.app/multiple",
        {
          hashtags: keywords,
        }
      );
      setIsFetching(false);

      setHashtags(response.data);
      console.log("data", response.data);
    } catch (e) {
      setIsFetching(false);

      console.log(e);
    }
  }

  const monthData = data[months[currentMonth]];
  const weekDayForFirstDateOfTheMonth = moment(
    monthData[0].date,
    "DD-MM-YYYY"
  ).weekday();

  let noOfBlankBoxesStart = weekDayForFirstDateOfTheMonth;
  let noOfDaysInMonth = data[months[currentMonth]].length;

  for (let i = 0; i < noOfBlankBoxesStart; i++) {
    gridboxes.push(<GridItemBlank />);
  }

  const isToday = (date, currentMonth) => {
    const splitDate = date.split("-");
    const d = new Date(splitDate[0] + currentMonth + splitDate[2]);

    const today = new Date();
    return (
      d.getDate() == today.getDate() &&
      d.getMonth() == today.getMonth() &&
      d.getFullYear() == today.getFullYear()
    );
  };

  for (let i = 0; i < noOfDaysInMonth; i++) {
    if (isToday(monthData[i].date, months[currentMonth])) {
      gridboxes.push(
        <GridItemCurrent dayOfMonth={i + 1} fact={monthData[i].event} />
      );
    } else {
      gridboxes.push(
        <GridItem
          colorTheme={monthData[i].colorTheme}
          dayOfMonth={i + 1}
          fact={monthData[i].event}
        />
      );
    }
  }

  for (let i = noOfBlankBoxesStart + noOfDaysInMonth; i < 42; i++) {
    gridboxes.push(<GridItemBlank />);
  }
  function GridItem({
    dayOfMonth = 1,
    fact = "Hello This is a fact",
    colorTheme,
  }) {
    let style = {};
    let symbol = "";
    if (colorTheme === "Hindu") {
      style = {
        backgroundColor: "#E94E0E",
        color: "white",
      };
      symbol = ohmSymbol;
    } else if (colorTheme === "Muslim") {
      style = {
        backgroundColor: "#008B00",
        color: "white",
      };
      symbol = moonSymbol;
    } else if (colorTheme === "Sikh") {
      style = {
        backgroundColor: "#F07C01",
        color: "white",
      };
      symbol = kandhaSymbol;
    } else if (colorTheme === "Christian") {
      style = {
        backgroundColor: "#E40613",
        color: "white",
      };
      symbol = crossSymbol;
    } else if (colorTheme === "World") {
      style = {
        backgroundColor: "#245CD4",
        color: "white",
      };
      symbol = worldSymbol;
    } else if (colorTheme === "Jain") {
      style = { backgroundColor: "#F14916", color: "white" };
      // symbol = ahimsaSymbol;
    } else if (colorTheme === "National") {
      style = { background: "#E8E8E3" };
      symbol = flag;
    } else if (colorTheme === "Birthday") {
      style = { backgroundColor: "#FA364C", color: "white" };
      symbol = baloonIcon;
    }
    return (
      <div
        className="calendar__grid__item"
        style={style}
        // onClick={() => {
        //   fetchHashtags(fact);
        //   toggleHashtagPopup();
        // }}
      >
        <img className="calendar__grid__item__symbol" src={symbol} />
        <div className="calendar__grid__item__dayOfMonth">{dayOfMonth}</div>
        <div className="calendar__grid__item__fact">{fact}</div>
      </div>
    );
  }

  function GridItemCurrent({ dayOfMonth = 1, fact = "Hello This is a fact" }) {
    return (
      <div
        className="calendar__grid__item calendar__grid__item--current"
        // onClick={() => {
        //   fetchHashtags(fact);
        //   toggleHashtagPopup();
        // }}
      >
        <div className="calendar__grid__item__dayOfMonth">{dayOfMonth}</div>
        <div className="calendar__grid__item__fact">{fact}</div>
      </div>
    );
  }

  function GridItemBlank({ dayOfMonth = "", fact = "" }) {
    return (
      <div className="calendar__grid__item calendar__grid__item--blank">
        <div className="calendar__grid__item__dayOfMonth">{dayOfMonth}</div>
        <div className="calendar__grid__item__fact">{fact}</div>
      </div>
    );
  }

  function handleNextMonthClick() {
    setCurrentMonth(getNextMonth(currentMonth));
    setNextMonth(getNextMonth(nextMonth));
    setPrevMonth(getNextMonth(prevMonth));
  }

  function handlePrevMonthClick() {
    setCurrentMonth(getPrevMonth(currentMonth));
    setNextMonth(getPrevMonth(nextMonth));
    setPrevMonth(getPrevMonth(prevMonth));
  }

  return (
    <Layout footer={false}>
      <main className="calendar">
        {/* <h1>Calender page</h1> */}
        <div className="calendar__container">
          <div className="calendar__container__left">
            <div className="calendar__container__left__year">2021</div>
            <div className="calendar__container__left__options">
              <button
                className="calendar__container__left__options__prev"
                onClick={handlePrevMonthClick}
              >
                <img alt="Prev Month" src={arrow}></img>
              </button>
              <div className="calendar__container__left__options__pm">
                {months[prevMonth]}
              </div>
              <div className="calendar__container__left__options__sm">
                {months[currentMonth]}
              </div>
              <div className="calendar__container__left__options__nm">
                {months[nextMonth]}
              </div>

              <button
                className="calendar__container__left__options__next"
                onClick={handleNextMonthClick}
              >
                <img alt="Next Month" src={arrow}></img>
              </button>
            </div>
          </div>
          <div className="calendar__container__right">
            <div className="calendar__grid">{gridboxes}</div>
          </div>
        </div>

        {showHashtagPopup && (
          <HashtagPopup
            loading={isFetching}
            onClickClose={toggleHashtagPopup}
            hashtags={hashtags}
          />
        )}
      </main>
    </Layout>
  );
}
